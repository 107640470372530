<template>
  <b-card-body class="statistics-body">
    <b-row>
      <b-link
        :to="{
          name: constRouter.CONTRACT.name,
          query: { status: item.status },
        }"
      >
        <b-col>
          <b-media no-body>
            <b-media-aside class="mr-2">
              <b-avatar size="48" :variant="variant">
                <feather-icon size="24" :icon="icon" />
              </b-avatar>
            </b-media-aside>
            <b-media-body class="my-auto" :class="`text-${variant}`">
              <h4 class="font-weight-bolder mb-0" :class="`text-${variant}`">
                {{ item.total || 0 }} {{ $t('contracts') }}
              </h4>
              <b-card-text class="font-small-3 mb-0">
                {{ $t(`contracts.status.${item.status}`) }}
              </b-card-text>
            </b-media-body>
          </b-media>
        </b-col>
      </b-link>
    </b-row>
  </b-card-body>
</template>

<script>
import {
  BCardText,
  BCardBody,
  BCardFooter,
  BRow,
  BCol,
  BMedia,
  BMediaAside,
  BAvatar,
  BMediaBody,
  BLink,
} from 'bootstrap-vue';
import constRouter from '@/constants/constRouter';

export default {
  components: {
    BCardText,
    BCardBody,
    BCardFooter,
    BRow,
    BCol,
    BMedia,
    BMediaAside,
    BAvatar,
    BMediaBody,
    BLink,
  },
  props: {
    item: {
      type: Object,
      default: null,
    },
    variant: {
      type: String,
      default: 'primary',
    },
    icon: {
      type: String,
      default: 'UserIcon',
    },
  },
  data() {
    return {
      constRouter,
    };
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/pages/dashboard-ecommerce.scss';
@import '@core/scss/vue/libs/chart-apex.scss';
</style>
