<template>
  <div>
    <b-card v-if="loading" class="text-center">
      <b-spinner style="width: 5rem; height: 5rem" :style="spinerStyle" :variant="spinerVariant" />
    </b-card>
    <slot v-else />
  </div>
</template>

<script>
import { BCard, BSpinner } from 'bootstrap-vue';

export default {
  name: 'LoaderCard',
  components: {
    BCard,
    BSpinner,
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    spinerVariant: {
      type: String,
      default: 'dark',
    },
    spinerStyle: {
      type: String,
      default: '',
    },
  },
  methods: {
    handle() {
      this.$emit('click');
    },
  },
};
</script>
