<template>
  <div>
    <loader-card
      v-if="
        $can(
          permissions.DASHBOARD.GET_v1_dashboard_count_contracts_by_status.action,
          permissions.DASHBOARD.GET_v1_dashboard_count_contracts_by_status.resource,
        )
      "
      :loading="loadingStatusCount"
    >
      <section v-if="statusCount.length > 0" class="d-flex">
        <b-card class="m-1" style="width: 25%">
          <dashboard-status-card icon="CheckIcon" variant="success" :item="collected" />
        </b-card>
        <!-- <b-card class="m-1" style="width: 20%">
          <dashboard-status-card icon="TrendingUpIcon" variant="primary" :item="ptp" />
        </b-card> -->
        <b-card class="m-1" style="width: 25%">
          <dashboard-status-card icon="PhoneIcon" variant="secondary" :item="notCall" />
        </b-card>
        <b-card class="m-1" style="width: 25%">
          <dashboard-status-card icon="ActivityIcon" variant="warning" :item="following" />
        </b-card>
        <b-card class="m-1" style="width: 25%">
          <dashboard-status-card icon="AlertCircleIcon" variant="danger" :item="difficult" />
        </b-card>
      </section>
    </loader-card>
    <loader-card
      v-if="
        $can(
          permissions.DASHBOARD.GET_v1_dashboard_count_contracts_by_partner.action,
          permissions.DASHBOARD.GET_v1_dashboard_count_contracts_by_partner.resource,
        )
      "
      :loading="loadingPartnerCount"
    >
      <section v-if="partnerCount.length > 0">
        <b-card class="m-1">
          <div class="d-flex flex-column">
            <div
              v-for="(partner, index) in partnerCount"
              :key="index"
              class="d-flex justify-content-start align-items-center"
              :class="{
                'border-bottom': index < partnerCount.length - 1,
              }"
            >
              <div class="p-2 font-medium-1 font-weight-bold" style="width: 150px">
                {{ partner.name }}
              </div>
              <div
                v-for="(code, codeIndex) in partner.action_codes"
                :key="codeIndex"
                class="p-2 text-center"
                style="width: 150px"
              >
                <div class="font-weight-bold">
                  {{ code.total }}
                </div>
                {{ code.action_code || $t('action_code_null') }}
              </div>
            </div>
          </div>
        </b-card>
      </section>
    </loader-card>
  </div>
</template>

<script>
import permissions from '@/constants/permissions';
import { countByStatus, countByPartner } from '@/apis/apiDashboard';
import { BAlert, BCard, BRow, BCol } from 'bootstrap-vue';
import LoaderCard from '@/components/base/LoaderCard.vue';
import DashboardStatusCard from './DashboardStatusCard.vue';

export default {
  components: {
    BAlert,
    BCard,
    BRow,
    BCol,
    LoaderCard,
    DashboardStatusCard,
  },
  data() {
    return {
      data: {},
      statusCount: [],
      loadingStatusCount: true,
      partnerCount: [],
      loadingPartnerCount: true,
      permissions,
    };
  },
  computed: {
    notCall() {
      return (
        this.statusCount.find((i) => i.status === 'not_call') || { status: 'not_call', total: 0 }
      );
    },
    ptp() {
      return this.statusCount.find((i) => i.status === 'ptp') || { status: 'ptp', total: 0 };
    },
    following() {
      return (
        this.statusCount.find((i) => i.status === 'following') || { status: 'following', total: 0 }
      );
    },
    collected() {
      return (
        this.statusCount.find((i) => i.status === 'collected') || { status: 'collected', total: 0 }
      );
    },
    giveUp() {
      return (
        this.statusCount.find((i) => i.status === 'give_up') || { status: 'give_up', total: 0 }
      );
    },
    difficult() {
      return (
        this.statusCount.find((i) => i.status === 'difficult') || { status: 'difficult', total: 0 }
      );
    },
  },
  mounted() {
    if (
      this.$can(
        this.permissions.DASHBOARD.GET_v1_dashboard_count_contracts_by_status.action,
        this.permissions.DASHBOARD.GET_v1_dashboard_count_contracts_by_status.resource,
      )
    ) {
      countByStatus()
        .then((res) => {
          this.statusCount = res.data;
        })
        .catch((err) => console.log(err))
        .then(() => {
          this.loadingStatusCount = false;
        });
    }

    if (
      this.$can(
        this.permissions.DASHBOARD.GET_v1_dashboard_count_contracts_by_partner.action,
        this.permissions.DASHBOARD.GET_v1_dashboard_count_contracts_by_partner.resource,
      )
    ) {
      countByPartner()
        .then((res) => {
          this.partnerCount = res.data.sort((a, b) => {
            if (a.action_codes.length < b.action_codes.length) {
              return 1;
            }
            if (a.action_codes.length > b.action_codes.length) {
              return -1;
            }
            return 0;
          });
        })
        .catch((err) => console.log(err))
        .then(() => {
          this.loadingPartnerCount = false;
        });
    }
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/pages/dashboard-ecommerce.scss';
@import '@core/scss/vue/libs/chart-apex.scss';
</style>
